/*
  Plugins
*/
//=require plugins/jquery.min.js
//=require plugins/swiper.js
//=require plugins/jquery.lazyload.mini.js
//=require plugins/jquery.colorbox-min.js

/*
  Custom
*/
//=require custom/*.js
