//Прелоадер
$(window).on('load', function() {
    $preloader = $('.preloader'),
        $loader = $preloader.find('.loader');
    $preloader.delay(300).fadeOut('slow');
});

//Плавный скролл    
$(document).ready(function() {
    $("header").on("click", "a[href^='#']", function(event) {
        event.preventDefault();
        var id = $(this).attr('href'),
            top = $(id).offset().top - 0;
        $('body,html').animate({ scrollTop: top }, 900);
    });
});

//Мобильное меню
var btn_navigation = document.querySelector(".btn-navigation"),
    btn_navigation__open = document.querySelector(".btn-navigation--open"),
    navigation = document.querySelector(".navigation"),
    navigation__link = document.querySelectorAll(".navigation__link"),
    body = document.querySelector("body");

if ($(btn_navigation).length) {
    btn_navigation.addEventListener("click", function(event) {
        event.preventDefault();
        btn_navigation.classList.toggle("btn-navigation--open");
        navigation.classList.toggle("navigation--hidden");
        body.classList.toggle("scroll--hidden");
    });
}
for (var n in navigation__link)
    navigation__link[n].onclick = function() {
        event.preventDefault();
        btn_navigation.classList.remove("btn-navigation--open");
        navigation.classList.add("navigation--hidden");
        body.classList.remove("scroll--hidden");
    };

//Модульное окно
(function(window) {
    'use strict';

    function classReg(className) {
        return new RegExp("(^|\\s+)" + className + "(\\s+|$)");
    }
    var hasClass, addClass, removeClass;

    if ('classList' in document.documentElement) {
        hasClass = function(elem, c) {
            return elem.classList.contains(c);
        };
        addClass = function(elem, c) {
            elem.classList.add(c);
        };
        removeClass = function(elem, c) {
            elem.classList.remove(c);
        };
    } else {
        hasClass = function(elem, c) {
            return classReg(c).test(elem.className);
        };
        addClass = function(elem, c) {
            if (!hasClass(elem, c)) {
                elem.className = elem.className + ' ' + c;
            }
        };
        removeClass = function(elem, c) {
            elem.className = elem.className.replace(classReg(c), ' ');
        };
    }

    function toggleClass(elem, c) {
        var fn = hasClass(elem, c) ? removeClass : addClass;
        fn(elem, c);
    }
    var classie = {
        hasClass: hasClass,
        addClass: addClass,
        removeClass: removeClass,
        toggleClass: toggleClass,
        has: hasClass,
        add: addClass,
        remove: removeClass,
        toggle: toggleClass
    };
    if (typeof define === 'function' && define.amd) {
        define(classie);
    } else {
        window.classie = classie;
    }
})(window);

var ModalEffects = (function() {
    function init() {
        [].slice.call(document.querySelectorAll('.modal__btn')).forEach(function(el, i) {
            var modal = document.querySelector('#' + el.getAttribute('data-modal')),
                close = modal.querySelector('.modal__close');

            function removeModal(hasPerspective) {
                classie.remove(modal, 'visible');
                body.classList.remove("scroll--hidden");
            }

            function removeModalHandler() {
                removeModal(classie.has(el));
            }
            el.addEventListener('click', function(ev) {
                classie.add(modal, 'visible');
                body.classList.add("scroll--hidden");
            });
            var overlay = document.querySelectorAll('.modal__overlay');
            if ($(overlay).length) {
                for (var o in overlay)
                    overlay[o].onclick = function() {
                        var modal = document.querySelectorAll('.modal');
                        event.preventDefault();
                        body.classList.remove("scroll--hidden");
                        for (var g in modal)
                            modal[g].classList.remove('visible');
                    };
            }
            close.addEventListener('click', function(ev) {
                ev.stopPropagation();
                removeModalHandler();
            });
        });
    }
    init();
})()

//Swiper
var galleryTop = new Swiper('#swiper-container-1', {
    nextButton: '#swiper-button-next-1',
    prevButton: '#swiper-button-prev-1',
    spaceBetween: 30,
    slidesPerView: '1',
    simulateTouch: false,
    effect: 'fade'
});
var galleryThumbs = new Swiper('#swiper-container-thumbs-1', {
    spaceBetween: 8,
    slidesPerView: '4',
    touchRatio: 0.2,
    slideToClickedSlide: true,
    centeredSlides: true
});
galleryTop.params.control = galleryThumbs;
galleryThumbs.params.control = galleryTop;
var swiper2 = new Swiper('#swiper-container-2', {
    nextButton: '#swiper-button-next-2',
    prevButton: '#swiper-button-prev-2',
    spaceBetween: 30,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper3 = new Swiper('#swiper-container-3', {
    nextButton: '#swiper-button-next-3',
    prevButton: '#swiper-button-prev-3',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '4',
    breakpoints: {
        991: {
            effect: 'slide',
            slidesPerView: '1',
            spaceBetween: 0
        }
    }
});
var swiper4 = new Swiper('#swiper-container-4', {
    nextButton: '#swiper-button-next-4',
    prevButton: '#swiper-button-prev-4',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper5 = new Swiper('#swiper-container-5', {
    nextButton: '#swiper-button-next-5',
    prevButton: '#swiper-button-prev-5',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper6 = new Swiper('#swiper-container-6', {
    nextButton: '#swiper-button-next-6',
    prevButton: '#swiper-button-prev-6',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper7 = new Swiper('#swiper-container-7', {
    nextButton: '#swiper-button-next-7',
    prevButton: '#swiper-button-prev-7',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper8 = new Swiper('#swiper-container-8', {
    nextButton: '#swiper-button-next-8',
    prevButton: '#swiper-button-prev-8',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper9 = new Swiper('#swiper-container-9', {
    nextButton: '#swiper-button-next-9',
    prevButton: '#swiper-button-prev-9',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper10 = new Swiper('#swiper-container-10', {
    nextButton: '#swiper-button-next-10',
    prevButton: '#swiper-button-prev-10',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper11 = new Swiper('#swiper-container-11', {
    nextButton: '#swiper-button-next-11',
    prevButton: '#swiper-button-prev-11',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper12 = new Swiper('#swiper-container-12', {
    nextButton: '#swiper-button-next-12',
    prevButton: '#swiper-button-prev-12',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper13 = new Swiper('#swiper-container-13', {
    nextButton: '#swiper-button-next-13',
    prevButton: '#swiper-button-prev-13',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper14 = new Swiper('#swiper-container-14', {
    nextButton: '#swiper-button-next-14',
    prevButton: '#swiper-button-prev-14',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper15 = new Swiper('#swiper-container-15', {
    nextButton: '#swiper-button-next-15',
    prevButton: '#swiper-button-prev-15',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper16 = new Swiper('#swiper-container-16', {
    nextButton: '#swiper-button-next-16',
    prevButton: '#swiper-button-prev-16',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper17 = new Swiper('#swiper-container-17', {
    nextButton: '#swiper-button-next-17',
    prevButton: '#swiper-button-prev-17',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper18 = new Swiper('#swiper-container-18', {
    nextButton: '#swiper-button-next-18',
    prevButton: '#swiper-button-prev-18',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper19 = new Swiper('#swiper-container-19', {
    nextButton: '#swiper-button-next-19',
    prevButton: '#swiper-button-prev-19',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper20 = new Swiper('#swiper-container-20', {
    nextButton: '#swiper-button-next-20',
    prevButton: '#swiper-button-prev-20',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper20 = new Swiper('#swiper-container-2999', {
    nextButton: '#swiper-button-next-2999',
    prevButton: '#swiper-button-prev-2999',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper20 = new Swiper('#swiper-container-2998', {
    nextButton: '#swiper-button-next-2998',
    prevButton: '#swiper-button-prev-2998',
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper20 = new Swiper('#swiper-container-2997', {
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
var swiper20 = new Swiper('#swiper-container-2996', {
    spaceBetween: 30,
    simulateTouch: false,
    slidesPerView: '1',
    effect: 'fade'
});
//Плавающие меню
$(document).ready(function(){
   if (screen.width >= 1279){

(function() {
    var a = document.querySelector('.performed-tabs__menu'),
        b = null,
        P = 0;
    window.addEventListener('scroll', Ascroll, false);
    document.body.addEventListener('scroll', Ascroll, false);

    function Ascroll() {
        if (b == null) {
            var Sa = getComputedStyle(a, ''),
                s = '';
            for (var i = 0; i < Sa.length; i++) {
                if (Sa[i].indexOf('overflow') == 0 || Sa[i].indexOf('padding') == 0 || Sa[i].indexOf('border') == 0 || Sa[i].indexOf('outline') == 0 || Sa[i].indexOf('background') == 0) {
                    s += Sa[i] + ': ' + Sa.getPropertyValue(Sa[i]) + '; '
                }
            }
            b = document.createElement('div');
            b.style.cssText = s + ' box-sizing: border-box; width: ' + a.offsetWidth + 'px;';
            a.insertBefore(b, a.firstChild);
            var l = a.childNodes.length;
            for (var i = 1; i < l; i++) {
                b.appendChild(a.childNodes[1]);
            }
            a.style.height = b.getBoundingClientRect().height + 'px';
            a.style.padding = '0';
            a.style.border = '0';
        }
        var Ra = a.getBoundingClientRect(),
            R = Math.round(Ra.top + b.getBoundingClientRect().height - document.querySelector('.performed .container').getBoundingClientRect().bottom + 0); // селектор блока, при достижении нижнего края которого нужно открепить прилипающий элемент
        if ((Ra.top - P) <= 0) {
            if ((Ra.top - P) <= R) {
                b.className = 'stop';
                b.style.top = -R + 'px';
            } else {
                b.className = 'sticky';
                b.style.top = P + 'px';
            }
        } else {
            b.className = '';
            b.style.top = '';
        }
        window.addEventListener('resize', function() {
            a.children[0].style.width = getComputedStyle(a, '').width
        }, false);
    }
})()
   }
});
//Картинки
var groups = {};
$('.galleryItem').each(function() {
    var id = parseInt($(this).attr('data-group'), 10);

    if (!groups[id]) {
        groups[id] = [];
    }

    groups[id].push(this);
});


$.each(groups, function() {

    $(this).magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        closeBtnInside: false,
        gallery: { enabled: true }
    })

});

//Табы
$(".tab-item").click(function() {
});
(function($) {
    $(function() {
        $('.tabs__caption').on('click', '.tab-item:not(.tb-active)', function() {
            o = $(this);
            animationOut();
            setTimeout(function() {
                o
                    .addClass('tb-active').siblings().removeClass('tb-active')
                    .closest('.tabs').find('.tab-content .tab-item').removeClass('tb-active').eq(o.index()).addClass('tb-active');
                animationIn();
            }, 600);
        });
        var tabIndex = window.location.hash.replace('#tab', '') - 1;
        if (tabIndex != -1) $('.tabs__caption .tab-item').eq(tabIndex).click();
        $('a[href*=#tab]').click(function() {
            var tabIndex = $(this).attr('href').replace(/(.*)#tab/, '') - 1;
            $('.tabs__caption .tab-item').eq(tabIndex).click();
        });
    });
})(jQuery);
function animationIn() {
    $('.performed-tab__content50, .performed-tab__content100, .not-allow-tabs__content-text h4,.not-allow-tabs__content-text ul,.not-allow-tabs__content-text i, .tab-item img, .swiper-container, .find-out-tab__content .tb-active, .tab-item__content-text-1, .tab-item__content-text-2, .tab-item__content-text-3, .performed-tab__content hr').addClass('up');
    $('.performed-tab__content50, .performed-tab__content100, .not-allow-tabs__content-text h4,.not-allow-tabs__content-text ul,.not-allow-tabs__content-text i, .tab-item img, .swiper-container, .find-out-tab__content .tb-active, .tab-item__content-text-1, .tab-item__content-text-2, .tab-item__content-text-3, .performed-tab__content hr').removeClass('down');
    setTimeout(function() {
        $('.performed-tab__content50, .performed-tab__content100, .not-allow-tabs__content-text h4,.not-allow-tabs__content-text ul,.not-allow-tabs__content-text i, .tab-item img, .swiper-container, .find-out-tab__content .tb-active, .tab-item__content-text-1, .tab-item__content-text-2, .tab-item__content-text-3, .performed-tab__content hr').removeClass('up');
    }, 600);
}
function animationOut() {
    $('.performed-tab__content50, .performed-tab__content100, .not-allow-tabs__content-text h4,.not-allow-tabs__content-text ul,.not-allow-tabs__content-text i, .tab-item img, .swiper-container, .find-out-tab__content .tb-active, .tab-item__content-text-1, .tab-item__content-text-2, .tab-item__content-text-3, .performed-tab__content hr').addClass('down');
}

//Акардион табы
    ! function(i) {
      var o, n;
      i(".not-allow-tabs__mobile-title").on("click", function() {
        o = i(this).parents(".not-allow-tabs__mobile-item"), n = o.find(".not-allow-tabs__mobile-info"),
          o.hasClass("not-allow-tabs__mobile-info--active") ? (o.removeClass("not-allow-tabs__mobile-info--active"),
            n.slideUp()) : (o.addClass("not-allow-tabs__mobile-info--active"), n.stop(!0, !0).slideDown(),
            o.siblings(".not-allow-tabs__mobile-info--active").removeClass("not-allow-tabs__mobile-info--active").children(
              ".not-allow-tabs__mobile-info").stop(!0, !0).slideUp())
      })
    }(jQuery);
    ! function(i) {
      var o, n;
      i(".solutions-tabs__mobile-title").on("click", function() {
        o = i(this).parents(".solutions-tabs__mobile-item"), n = o.find(".solutions-tabs__mobile-info"),
          o.hasClass("solutions-tabs__mobile-info--active") ? (o.removeClass("solutions-tabs__mobile-info--active"),
            n.slideUp()) : (o.addClass("solutions-tabs__mobile-info--active"), n.stop(!0, !0).slideDown(),
            o.siblings(".solutions-tabs__mobile-info--active").removeClass("solutions-tabs__mobile-info--active").children(
              ".solutions-tabs__mobile-info").stop(!0, !0).slideUp())
      })
    }(jQuery);

//Две или более reCaptcha на одной странице
function hideAllError(str) {return true;}
window.onerror = hideAllError;
var onloadCallback = function() {
    mysitekey = '6Le-hzEUAAAAAAfMU-9vWGO0N3EHoD40hhv2h2r8';
    grecaptcha.render('recaptcha-1', {
        'sitekey' : mysitekey,
    });
    grecaptcha.render('recaptcha-2', {
        'sitekey' : mysitekey
    });
    grecaptcha.render('recaptcha-3', {
        'sitekey' : mysitekey
    });
    grecaptcha.render('recaptcha-4', {
        'sitekey' : mysitekey
    });
    grecaptcha.render('recaptcha-5', {
        'sitekey' : mysitekey
    });
    grecaptcha.render('recaptcha-6', {
        'sitekey' : mysitekey
    });
};

//Валидация и отправка
function send(o,b){
    n = o.closest('form').find('.'+b+'-form__input--name');
    t = o.closest('form').find('.'+b+'-form__input--tel');
    k = o.closest('form').find('.'+b+'-form__conditionnot-input');
    nn = n.val();
    tt = t.val();
    if(k.prop("checked")==false) o.closest('form').find('.'+b+'-form__conditionnot-text').addClass("input-file--error"); else o.closest('form').find('.'+b+'-form__conditionnot-text').removeClass("input-file--error"); 
    if(nn.length<3)  n.addClass("input-file--error"); else n.removeClass("input-file--error"); 
    if(tt.length<9)  t.addClass("input-file--error"); else t.removeClass("input-file--error"); 
    
    input = o.closest('form').find(".input-file")[0];
    if(input!==undefined){
        file = input.files[0];
        if(file!==undefined){
            if(file.size>2097152) {
                o.closest('form').find('.'+b+'-form__input-file span').addClass("input-file--error");
            }
            else 
                o.closest('form').find('.'+b+'-form__input-file span').removeClass("input-file--error"); 
        }
    }
    if(o.closest('form').find('.input-file--error').length==0) {
        var fd = new FormData(o.closest('form')[0]);
        $.ajax({
            url: 'send.php',
            data: fd,
            processData: false,
            contentType: false,
            type: 'POST',
            success: function (data) {
                if(data=='ok')
                      location.href="thank.html"
                    else {
                      alert("Не пройдена каптча! Попробуйте еще раз!");
                    };
            }
        });
    }
}
(function($) {
    $(function() {
        $(document).on('click', '.modal-form__btn--phone', function(e) {
            e.preventDefault();
            send($(this), 'modal');
        });
        $(document).on('click', '.modal-form__btn--order', function(e) {
            e.preventDefault();
            send($(this), 'modal');
        });
        $(document).on('click', '.estimate-form__btn', function(e) {
            e.preventDefault();
            send($(this), 'estimate');
        });
        $(document).on('click', '.estimate2-form__btn', function(e) {
            e.preventDefault();
            send($(this), 'estimate2');
        });
        $(document).on('click', '.discuss-form__btn', function(e) {
            e.preventDefault();
            send($(this), 'discuss');
        });
        $(document).on('click', '.consultation-form__btn', function(e) {
            e.preventDefault();
            send($(this), 'consultation');
        });
    });
})(jQuery);